# CoffeeScript

class FeatureDecorator
    decorate: (feature) ->
    reclaim: (feature) ->

    registerWithSource: (source) ->
        @_decorateWrapper = (event) =>
            @decorate event.feature
        @_reclaimWrapper = (event) =>  @reclaim event.feature
        source.on 'addfeature', @_decorateWrapper
        source.on 'removefeature', @_reclaimWrapper
    unregisterWithSource: (source) ->
        source.un 'addfeature', @_decorateWrapper if @_decorateWrapper
        source.un 'removefeature', @_reclaimWrapper if @_reclaimWrapper

class AscendingIdProvider extends FeatureDecorator
    constructor: (start = 0, existingIds) ->
        if existingIds
            @next = Math.max existingIds..., start

            @available = (x for x in [start..@next] when x not in existingIds)

            @next++
        else
            @available = []
            @next = start

    decorate: (feature) ->
        if @available.length
            feature.setId @available[0]
            @available = @available[1..]
        else
            feature.setId @next
            @next++

    reclaim: (feature) ->
        @available.push feature.getId()

module.exports = {
    FeatureDecorator,
    AscendingIdProvider
}
