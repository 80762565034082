# CoffeeScript

Control = require('ol/control/Control').default

class Toolbar extends Control
    constructor: (options) ->
        @options = $.extend {}, options

        @$element = $ '<div class="ol-toolbar ol-unselectable ol-control">'
        @$element.on 'click', 'button', -> false

        Control.call this,
            element: @$element[0]
            target: @options.target

module.exports = {
    Toolbar
}

module.exports.__esModule = true
module.exports.default = Toolbar
