# CoffeeScript

{FeatureDecorator} = require 'src/coffee/controls/map/utilities/feature-tools'
{getStyle} = require 'src/coffee/controls/map/utilities/styling'
{Plugin, Mode} = require './base'

Draw = require('ol/interaction/Draw').default
LinearRing = require('ol/geom/LinearRing').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

{getModifierKeyState} = require('src/js/infrastructure/keyboard')

class DefaultIdProvider extends FeatureDecorator
    next: 0
    decorate: (feature) ->
        feature.setId @next++

class AddGeometryPluginBase extends Plugin
    interaction: null
    featureLimitReached: true
    geometryType: ''

    constructor: (@layerName, @maxFeatureCount, @tooltips) ->

    init: ->
        @_initializeLayer()
        @_initializeInteraction()
        if @_adjustInteraction
            @_adjustInteraction()
        @setIdProvider new DefaultIdProvider

    setIdProvider: (idProvider) ->
        if @idProvider
            @source.un 'removefeature', @_idProviderReclaimWrapper
        @idProvider = idProvider
        @_idProviderReclaimWrapper = (event) => @idProvider.reclaim event.feature
        @source.on 'removefeature', @_idProviderReclaimWrapper

    _initializeLayer: ->
        @layer = null
        for layer in @webgis.layers
            if layer.get('name') is @layerName
                @layer = layer
                break
        unless @layer
            @layer = new VectorLayer
                source: new VectorSource
                style: getStyle 'default'
            @layer.set 'name', @layerName
            @map.addLayer(@layer)
            @webgis.registerLayer @layer
        @source = @layer.getSource()
        @source.on 'addfeature', @_checkMaximumNumberOfFeatures

    _initializeInteraction: ->
        @interaction = new Draw
            source: @source
            type: @geometryType
        @interaction.on 'drawend', (evt) => @_finishFeature evt.feature
        @map.addInteraction @interaction
        @interaction.setActive false

    _tryEnableInteraction: ->
        if @canAddMoreFeatures()
            @interaction.setActive true

    _disableInteraction: ->
        @interaction.setActive false

    _finishFeature: (feature) ->
        @idProvider?.decorate feature

    canAddMoreFeatures: -> @maxFeatureCount <= 0 or @source.getFeatures().length < @maxFeatureCount

    _checkMaximumNumberOfFeatures: =>
        @_disableInteraction() unless @canAddMoreFeatures()


class AddLinesPlugin extends AddGeometryPluginBase
    geometryType: 'LineString'
    constructor: (layerName, maxFeatureCount, tooltips) ->
        super
        @modes = [
            new Mode tooltips.newLine,
                icon: 'icon icon-ix-add-line'
                enable: => @_tryEnableInteraction()
                disable: => @_disableInteraction()
        ]

class AddPolygonsPlugin extends AddGeometryPluginBase
    geometryType: 'Polygon'
    constructor: (layerName, maxFeatureCount, tooltips) ->
        super
        @modes = [
            new Mode tooltips.newPolygon,
                icon: 'icon icon-ix-add-polygon'
                enable: => @_tryEnableInteraction()
                disable: => @_disableInteraction()
        ]

    _adjustInteraction: ->
        @existingPolygon = null

        @interaction.on 'drawstart', (event) =>
            features = @source.getFeaturesAtCoordinate(event.feature.getGeometry().getFirstCoordinate())
            if getModifierKeyState().shift and features.length
                @existingPolygon = features[0]
            else
                @existingPolygon = null
            return true

        @interaction.on 'drawend', (event) =>
            if @existingPolygon
                coordinates = event.feature.getGeometry().getCoordinates()[0]
                @existingPolygon.getGeometry().appendLinearRing(new LinearRing(coordinates))
                setTimeout((=> @source.removeFeature(event.feature)), 0)
            @existingPolygon = null

class AddPointsPlugin extends AddGeometryPluginBase
    geometryType: 'Point'
    constructor: (layerName, maxFeatureCount, tooltips) ->
        super
        @modes = [
            new Mode tooltips.newPoint,
                icon: 'icon icon-ix-add-points'
                enable: => @_tryEnableInteraction()
                disable: => @_disableInteraction()
        ]

module.exports = {
    AddLinesPlugin,
    AddPolygonsPlugin,
    AddPointsPlugin
}
