{register} = require 'src/js/infrastructure/setup-registry'

{showSettings} = require 'src/coffee/areas/printing'

setup = ($ctx) ->

    $btn = $('#print-button').disable()

    # handle map selection changes
    $ctx.find('input.mapcheckbox').on 'change', ->
        if $('input.mapcheckbox:checked').length > 0
            $btn.enable()
        else
            $btn.disable()

    # load the print options modal
    $btn.on 'click', ->
        data =
            ids : $('.mapcheckbox:checked').map ->
                $(this).data 'id'
            .toArray()
        showSettings $btn.data('options-url'), data

register 'App.DataTransfer.ZoneMapsPrinting.Setup', setup