{register} = require 'src/js/infrastructure/setup-registry'

setup = ($ctx) ->
    $button = $ctx.find('#submitSelectMap')
    $selectAll = $ctx.find('#select-all')
    $deSelectAll = $ctx.find('#deselect-all')

    $parcels = $ctx.find('#parcelIds')
    $orgUnits = $ctx.find('#orgUnits')

    parcelsSelectize = $parcels[0].selectize
    orgUnitsSelectize =  $orgUnits[0].selectize

    allParcels = Object.values parcelsSelectize.options

    selectedOrgUnits = []

    filterByOrgUnit = (cultivation) ->
        not selectedOrgUnits or selectedOrgUnits.indexOf(cultivation.parcelinfo.orgUnitId) > -1

    updateSelectizeOptions = (selectize, newOptions) ->
        newOptionsLookup = {}
        for option in newOptions
            newOptionsLookup[option.value] = option

        added = newOptions.filter (x) -> not selectize.options[x.value]
        removed = Object.values(selectize.options).filter (existingOption) -> not newOptionsLookup[existingOption.value]

        for option in added
            selectize.addOption option
        for option in removed
            selectize.removeOption option.value
        options = Object.values selectize.options
        options.sort (a,b) -> sortByMultiple a, b, ['text']

        selectize.clear()
        selectize.clearOptions()
        selectize.addOption options
        selectize.refreshOptions(false)


    sortByMultiple = (a, b, keys) ->
        return r if (r = sortBy key, a, b) for key in keys
        return 0

    sortBy = (key, a, b, r) ->
        r = if r then 1 else -1
        return -1*r if a[key] > b[key]
        return +1*r if a[key] < b[key]
        return 0

    onFilterChange = ->
        selectedOrgUnits = $orgUnits.val()
        updateSelectizeOptions parcelsSelectize, allParcels.filter filterByOrgUnit
        checkSelectionButtons()

    $orgUnits.on 'change', onFilterChange

    checkContinue = ->
        unless $('#parcelIds').val()
            $button.disable()
        else
            $button.enable()

    checkSelectionButtons = ->
        options = Object.values $parcels[0].selectize.options
        if $orgUnits.val()
            $selectAll.enable()
        else
            $selectAll.disable()

        if $parcels.val()
            $deSelectAll.enable()
            if options.length is $parcels.val().length
                $selectAll.disable()
        else
            $deSelectAll.disable()

    $parcels.on 'change', ->
        checkContinue()
        checkSelectionButtons()

    parcelsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    orgUnitsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    $selectAll.on 'click', ->
        possibleParcels = Object.values(parcelsSelectize.options)
        for p in possibleParcels
            parcelsSelectize.addItem(p.value, true)
        $('div.active').removeClass('active')
        parcelsSelectize.refreshOptions(false)
        checkSelectionButtons()
        checkContinue()

    $deSelectAll.on 'click', ->
        possibleParcels = Object.values(parcelsSelectize.options)
        for p in possibleParcels
            parcelsSelectize.removeItem(p.value, true)
        $('div.active').removeClass('active')
        parcelsSelectize.refreshOptions(false)
        checkSelectionButtons()
        checkContinue()

    onFilterChange()
    checkContinue()

register('App.DataTransfer.SelectParcel.Setup', setup)
