# CoffeeScript

Feature = require('ol/Feature').default
Style = require('ol/style/Style').default
Stroke = require('ol/style/Stroke').default
Circle = require('ol/style/Circle').default
Fill = require('ol/style/Fill').default
Text = require('ol/style/Text').default

styles =
    default:
        fill: 'rgba(55,148,193,0.2)'
        stroke: 'rgb(55,148,193)'
        label: true
        maxLabelResolution: 20
    selected:
        fill: 'rgba(255,0,255,0.3)'
        stroke: '#ff0000'
        label: true

styleCache = {}


defineStyle = (name, styleDefinition) ->
    if styleDefinition.dynamic instanceof Function
        styleDefinition.dynamic = [styleDefinition.dynamic]

    styleDefinition.dynamic or= []

    if styleDefinition.label
        styleDefinition.dynamic.push dynamicTextFunction

    styles[name] = styleDefinition
    styleCache[name] = createStyle styleDefinition

createStyle = (styleDefinition) ->
    strokeWidth = styleDefinition.strokeWidth or 2

    image = styleDefinition.image or new Circle
        radius: 5
        fill: new Fill
            color: styleDefinition.fill
        stroke: new Stroke
            color: styleDefinition.stroke
            width: strokeWidth

    stroke = new Stroke
        color: styleDefinition.stroke
        width: strokeWidth

    fill = new Fill
        color: styleDefinition.fill

    textStroke = new Stroke
        color: styleDefinition.stroke
        width: 3

    textFill = new Fill
        color: '#ffffff'

    return new Style
        fill: fill
        stroke: stroke
        image: image
        text: if not styleDefinition.label then undefined else new Text
            textAlign: 'left'
            textBaseline: 'left'
            text: 'placeholder'
            font: '12px CalibreWeb'
            fill: textFill
            stroke: textStroke
            offsetX: 8
            offsetY: 3.5
            rotation: 0

dynamicTextFunction = (definition, style, feature, resolution) ->
    if definition.label
        maxRes = definition.maxLabelResolution
        if not maxRes or resolution <= maxRes
            text = '' + (feature.get('name') or feature.get('label') or feature.get('text') or '')
            style.getText().setText text
        else
            style.getText().setText ''

getStyle = (styleName) ->
    (feature, resolution) ->
        if this instanceof Feature
            feature = this
            resolution = arguments[0]

        definition = styles[styleName]
        style = styleCache[styleName]

        for f in definition.dynamic
            f definition, style, feature, resolution

        return style

selectStyleByFeature = (selectorFunction) ->
    return (feature, resolution) ->
        if this instanceof Feature
            feature = this
            resolution = arguments[0]
        getStyle(selectorFunction(feature))(feature, resolution)

defineStyle 'default', styles.default
defineStyle 'selected', styles.selected

module.exports = {
    styles,
    defineStyle,
    getStyle,
    selectStyleByFeature
}
