# CoffeeScript

{Menu} = require './controls/menu'
{getStyle} = require 'src/coffee/controls/map/utilities/styling'
{Plugin, ModalAction, Mode, Action} =  require './plugins/base'

Collection = require('ol/Collection').default
WKT = require('ol/format/WKT').default
GeoJSON = require('ol/format/GeoJSON').default
Select = require('ol/interaction/Select').default
Modify = require('ol/interaction/Modify').default
Pointer = require('ol/interaction/Pointer').default
{shiftKeyOnly, singleClick} = require('ol/events/condition')

class WebGIS
    ###
        Contructor, enables the Adding, Deleting and Editing mode
    ###
    constructor: (@mapcontrol, editableLayers, @options) ->
        @map = @mapcontrol.map
        @$target = $ @map.getTarget()
        # tabindex set for handling keyboard shortcuts inside the map border scope
        # @$target.parent().parent().attr 'tabindex', 1

        # initialize all instance properties
        activeMode: null
        activeModalAction: null
        @state =
            added: {}
            updated: {}
            deleted: {}
        @plugins = []
        @modes = []
        @interactions = {}
        @layers = []

        for layer in editableLayers
            @registerLayer layer

        @_createButtonContainers()

        @_initializeSelectMode()

        @_initializeEventInteractions()

        @switchMode 0

        @_initializePlugins()

        @_selectionChange() # to disable all 'selection' context buttons
        #this is for testing purposes only
        #window.webgis = this

    registerLayer: (layer) ->
        @layers.push layer
        src = layer.getSource()
        src.on 'addfeature', (event) =>
            @notifyFeatureAdded event.feature
        src.on 'changefeature', (event) =>
            @notifyFeatureModified event.feature
        src.on 'removefeature', (event) =>
            @notifyFeatureRemoved event.feature

    unregisterLayer: (layer) ->
        @layers = @layers.filter((x) -> layer isnt x)

        src = layer.getSource()
        src.un 'addfeature'
        src.un 'changefeature'
        src.un 'removefeature'

    _initializePlugins: ->
        for plugin in @options.plugins
            @registerPlugin plugin

    registerPlugin: (plugin) ->
        unless plugin instanceof Plugin
            throw 'plugin for map has to be instance of Controls.Map.WebGIS.Plugin'
        plugin.webgis = this
        plugin.map = @map
        plugin.mapcontrol = @mapcontrol
        plugin.init @mapcontrol.options.promises

        @plugins.push plugin

        for action in plugin.actions or []
            action.plugin = plugin

            @_createActionButton action
            @_registerActionEvents action

            @modes[0].actions.push action

        for mode in plugin.modes or []
            mode.plugin = plugin
            for action in mode.actions
                action.plugin = plugin

                @_createActionButton action
                @_registerActionEvents action

            @modes.push mode

            @_createModeButton mode
            @_registerModeEvents mode

        @enablePlugin plugin

        @_refreshActionButtons() if @activeMode

    enablePlugin: (plugin) ->
        plugin.enable()
        plugin.enabled = true

    disablePlugin: (plugin) ->
        if @activeMode.plugin is plugin
            switchMode 0
        plugin.disable()
        plugin.enabled = false

    _createButtonContainers: ->
#        $div = @$target.parent().find 'div.map-editor.toolbar'
#        @$modeButtonContainer = $ '<div>'
#            .addClass 'map-editor mode-button-container btn-group'
#            .appendTo $div
#        @$actionButtonContainer = $ '<div>'
#            .addClass 'map-editor action-button-container'
#            .appendTo $div

        @menu = new Menu
        @map.addControl @menu

    _createActionButton: (action) ->
        icon = $ '<i>'
            .addClass action.icon
        action.$button = $ '<button>'
            .addClass 'map-editor action-button btn btn-default btn-sm'
            .attr 'title', action.name
            .append icon
        if action instanceof ModalAction
            action.$button.addClass 'modal-action'

    _createModeButton: (mode) ->
        icon = $ '<i>'
#            .addClass (if mode.icon?.match(/icon/) then 'fa fa-image text-danger' else mode.icon)
            .addClass mode.icon
        mode.$button = $ '<button>'
            .addClass 'map-editor mode-button btn btn-default'
            .append icon
            .attr 'title', mode.name
#            .appendTo @$modeButtonContainer

        @menu.addMode mode

    _registerModeEvents: (mode) ->
        index = @modes.indexOf mode
        mode.$button.click (evt) =>
            evt.preventDefault()
            @switchMode index

    finishModalAction: ->
        @activeMode?.enable()
        @activeModalAction?.disable()
        @activeModalAction?.$button.removeClass 'active'
        @activeModalAction = null
        if @activeMode is @modes[0]
            @interactions.generic.setActive false

    startModalAction: (action) ->
        @finishModalAction()
        @activeMode?.disable()
        action.$button.addClass 'active'
        @activeModalAction = action
        action.enable()
        @interactions.generic.setActive true

    executeAction: (action) ->
        # console.log 'click on action: ' + action.name
        @finishModalAction()
        switch action.context
            when 'selection'
                action.onExecute @interactions.select.getFeatures().getArray()
            when 'global'
                action.onExecute()

    _registerActionEvents: (action) ->
        if action instanceof ModalAction
            action.$button.click (evt) =>
                evt.preventDefault()
                if @activeModalAction is action
                    @finishModalAction()
                else
                    @startModalAction action
        else if action instanceof Action
            action.$button.click (evt) =>
                evt.preventDefault()
                @executeAction action

    switchMode: (i) =>
        return if @activeMode is @modes[i]

        @finishModalAction()

        if i is 0
            @interactions.generic.setActive false
        else
            @interactions.generic.setActive true

        # console.log 'switching mode to '+i

        if @activeMode
            @activeMode.disable()
            @activeMode.$button.removeClass 'active'

        @activeMode = @modes[i]

        # console.log 'new active mode:'
        # console.log @activeMode

        if @activeMode
            @activeMode.enable()
            @activeMode.$button.addClass 'active'

            @_refreshActionButtons()

            @menu.activate @activeMode

#        if i == 0
#            @map.removeInteraction @interactions.generic

    _refreshActionButtons: ->
        # TODO cancel any active ModalActions
        selectedFeatureCount = @interactions.select.getFeatures().getArray().length

#        @$actionButtonContainer.children().detach()
        for action in @activeMode.actions
#            @$actionButtonContainer.append action.$button
            if action.context is 'selection' and not selectedFeatureCount
                action.$button.disable()
            else
                action.$button.enable()

    deleteFeatures: (features) ->
        for feature in features
            id = feature.getId()
            # console.log "trying to delete feature '#{id}'"

            if @_activeSource?.getFeatureById id
                # console.log 'feature is in active source'
                @_activeSource.removeFeature feature
            else
                # console.log 'feature is in editable layer'
                sources = (layer.getSource() for layer in @layers)
                filtered = (src for src in sources when feature in src.getFeatures())
                if filtered.length is 1
                    src = filtered[0]
                    src.removeFeature feature

    allLayers: (layer) => layer in @layers
    editableLayers: (layer) => layer in @layers and layer.get 'editable'
    interactiveLayers: (layer) => layer in @layers and layer.get 'interactive'

    _initializeSelectMode: ->
        @interactions.select = new Select
            filter: (feature, layer) => @interactiveLayers(layer)
            style: getStyle 'selected'

        @editableFeatures = new Collection

        @interactions.modify = new Modify
            features: @editableFeatures
            deleteCondition: (event) ->
                shiftKeyOnly(event) and singleClick(event)

        @interactions.select.getFeatures().on 'change:length', @_selectionChange

        @map.addInteraction @interactions.select
        @map.addInteraction @interactions.modify

        selectMode = new Mode @options.tooltips.selectMode,
            icon: 'icon icon-ix-edit-points'
            hotkey: 83 # s
            enable: =>
                # console.log 'enabling select mode'
                @interactions.select.setActive true
                @interactions.modify.setActive true
            disable: =>
                # console.log 'disabling select mode'
                @interactions.select.setActive false
                @interactions.modify.setActive false
                @interactions.select.getFeatures().clear()
            actions: [
                new Action @options.tooltips.deleteFeature,
                    icon: 'icon icon-ix-delete'
                    hotkey: 46 # delete
                    context: 'selection'
                    onExecute: (features) =>
                        @deleteFeatures features
                        @clearSelection()
            ]

        plugin = new Plugin
        plugin.modes = [selectMode]

        @registerPlugin plugin

    _initializeEventInteractions: ->
        @interactions.generic = new Pointer
            handleDownEvent: @_dispatchMouseDown

        @map.addInteraction @interactions.generic

#        @$target.on 'keydown', (event) =>
#            console.log 'keydown: '+event.keyCode
#            for action in @activeMode.actions
#                if action.hotkey is event.keyCode
#                    if action.context is 'selection'
#                        action.onExecute @interactions.select.getFeatures().getArray()
#                        return false

    _dispatchMouseDown: (evt) =>
        return unless @activeModalAction or @activeMode isnt @modes[0]

        console.log "mousedown at x=#{evt.coordinate[0]}, y=#{evt.coordinate[1]}, px=#{evt.pixel[0]}, py=#{evt.pixel[1]}"

        dispatcher = (feature, layer) =>
            # console.log 'executing onFeatureClick with feature:'
            # console.log feature
            if layer
                return (@activeModalAction or @activeMode)?.onFeatureClick feature, layer

        clickedOnFeature = @map.forEachFeatureAtPixel evt.pixel, dispatcher, {layerFilter: @interactiveLayers}

        success = clickedOnFeature

        if not clickedOnFeature
            # console.log 'executing onMapClick with coordinate:'
            # console.log evt.coordinate
            success = (@activeModalAction or @activeMode)?.onMapClick(evt.coordinate)
        if success and @activeModalAction
            @finishModalAction()
        return success

    _selectionChange: (evt) =>
        selectedFeatures = @interactions.select.getFeatures().getArray()
        for action in @activeMode.actions
            if action.context is 'selection'
                if selectedFeatures.length is 0 then action.$button.disable() else action.$button.enable()

        @editableFeatures.clear()

        if selectedFeatures.length
            editableFeatures = []
            for layer in @layers
                continue if not @editableLayers(layer)

                editableFeatures.push layer.getSource().getFeatures()...

            selectedEditableFeatures = selectedFeatures.filter((x) -> x in editableFeatures)

            if selectedEditableFeatures.length
                @editableFeatures.push selectedEditableFeatures...

    notifyFeatureModified: (feature) =>
        id = feature.getId()
        unless id of @state.added
            @state.updated[id] = feature

    notifyFeatureAdded: (feature) =>
        @state.added[feature.getId()] = feature

    notifyFeatureRemoved: (feature) =>
        id = feature.getId()
        if id of @state.added
            delete @state.added[id]
        else
            delete @state.updated[id] if id of @state.updated
            @state.deleted[feature.getId()] = ($.extend true, {}, feature)

    #############################################
    ###          Public Methods               ###
    #############################################

    getModifiedGeometriesGeoJSON: ->
        format = new GeoJSON()
        opt =
            dataProjection: 'EPSG:4326'
            featureProjection: 'EPSG:900913'
        write = (feature) -> format.writeFeature feature, opt
        @getModifiedGeometries(format, write)

    getModifiedGeometriesWKT: ->
        format = new WKT()
        opt =
            dataProjection: 'EPSG:4326'
            featureProjection: 'EPSG:900913'
        write = (feature) ->
            data = feature.getProperties()
            data.geometry = format.writeFeature feature, opt
            return data
        @getModifiedGeometries(format, write)

    getModifiedGeometries: (format, write) ->
        data =
            added: (feature for id, feature of @state.added).map write
            updated: (feature for id, feature of @state.updated).map write
            deleted: (feature for id, feature of @state.deleted).map write
        return data

    getFeatures: (featureFilter, layerFilter) ->
        featureFilter or= -> true
        layerFilter or= -> true

        features = []
        for layer in @layers.filter layerFilter
            features.push layer.getSource().getFeatures().filter(featureFilter)...
        return features

    resetState: ->
        @state.added = {}
        @state.updated = {}
        @state.deleted = {}

    clearSelection: ->
        @interactions.select.getFeatures().clear()

    getSelectedFeatures: ->
        features = @interactions.select.getFeatures().getArray()
        format = new GeoJSON()
        geoJSON = format.writeFeatures features
        return Features: features, GeoJSON: geoJSON

module.exports = {
    WebGIS
}

module.exports.__esModule = true
module.exports.default = WebGIS
