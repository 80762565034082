{register} = require 'src/js/infrastructure/setup-registry'

setup = ($ctx) ->
    $button = $ctx.find('#submitSelectMap')
    $selectAll = $ctx.find('#select-all')
    $deSelectAll = $ctx.find('#deselect-all')

    $years = $ctx.find('#year')
    $orgUnits = $ctx.find('#orgUnits')
    $cropTypes = $ctx.find('#cropTypes')
    $cultivations = $ctx.find('#cultivationIds')

    yearsSelectize = $years[0].selectize
    orgUnitsSelectize = $orgUnits[0].selectize
    cropTypesSelectize = $cropTypes[0].selectize
    cultivationsSelectize = $cultivations[0].selectize

    allYears = Object.values yearsSelectize.options
    allOrgUnits = Object.values orgUnitsSelectize.options
    allCropTypes = Object.values cropTypesSelectize.options
    allCultivations = Object.values cultivationsSelectize.options

    selectedYears = []
    selectedOrgUnits = []
    selectedCropTypes = []

    filterByYear = (selection) ->
        not selectedYears or selectedYears.indexOf(selection.parcelinfo.cultivationYear) > -1

    filterByOrgUnit = (selection) ->
        not selectedOrgUnits or selectedOrgUnits.indexOf(selection.parcelinfo.companyId) > -1

    filterByCropType = (selection) ->
        not selectedCropTypes or selectedCropTypes.indexOf(selection.parcelinfo.cropTypeId) > -1

    filterByAll = (selection) ->
        filterByYear(selection) and filterByOrgUnit(selection) and filterByCropType(selection)

    updateSelectizeOptions = (selectize, newOptions) ->
        newOptionsLookup = {}
        for option in newOptions
            newOptionsLookup[option.value] = option

        added = newOptions.filter (x) -> not selectize.options[x.value]
        removed = Object.values(selectize.options).filter (existingOption) -> not newOptionsLookup[existingOption.value]

        for option in added
            selectize.addOption option
        for option in removed
            selectize.removeOption option.value

        selectize.settings.sortField = 'text'

    distinct = (x, i, arr) -> arr.indexOf(x) is i

    updateFilterOptions = ->
        availableYears = allCultivations.filter (x) -> filterByOrgUnit(x) and filterByCropType(x)
        .map (x) -> x.parcelinfo.cultivationYear
        .filter distinct

        filteredYears = allYears.filter (x) -> availableYears.indexOf(x.value) > -1
        updateSelectizeOptions(yearsSelectize, filteredYears)

        availableOrgUnits = allCultivations.filter (x) -> filterByYear(x) and filterByCropType(x)
        .map (x) -> x.parcelinfo.companyId
        .filter distinct

        filteredOrgUnits = allOrgUnits.filter (x) -> availableOrgUnits.indexOf(x.value) > -1
        updateSelectizeOptions(orgUnitsSelectize, filteredOrgUnits)

        availableCropTypes = allCultivations.filter (x) -> filterByYear(x) and filterByOrgUnit(x)
        .map (x) -> x.parcelinfo.cropTypeId
        .filter distinct

        filteredCropTypes = allCropTypes.filter (x) -> availableCropTypes.indexOf(x.value) > -1
        updateSelectizeOptions(cropTypesSelectize, filteredCropTypes)

    checkSelectionButtons = ->
        options = Object.values $cultivations[0].selectize.options
        if $orgUnits.val() or $years.val() or $cropTypes.val()
            $selectAll.enable()
        else
            $selectAll.disable()

        if $cultivations.val()
            $deSelectAll.enable()
            if options.length is $cultivations.val().length
                $selectAll.disable()
        else
            $deSelectAll.disable()

    onFilterChange = ->
        selectedYears = $years.val()
        if selectedYears
            selectedYears = selectedYears.map parseInt
        selectedOrgUnits = $orgUnits.val()
        selectedCropTypes = $cropTypes.val()

        updateSelectizeOptions cultivationsSelectize, allCultivations.filter filterByAll
        updateFilterOptions()
        checkSelectionButtons()

    $years.on 'change', onFilterChange
    $orgUnits.on 'change', onFilterChange
    $cropTypes.on 'change', onFilterChange

    checkContinue = ->
        unless $('#cultivationIds').val()
            $button.disable()
        else
            $button.enable()

    $cultivations.on 'change', ->
        checkContinue()
        checkSelectionButtons()

    yearsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    orgUnitsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    cropTypesSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    cultivationsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')
        
        cultivationsSelectize.settings.sortField = 'text'

    $selectAll.on 'click', ->
        possibleCultivations = Object.values(cultivationsSelectize.options)
        possibleCultivations.sort (a, b) -> sortByMultiple a, b, ['text']

        for p in possibleCultivations
            cultivationsSelectize.addItem(p.value, true)
        $('div.active').removeClass('active')
        cultivationsSelectize.settings.sortField = 'text'
        cultivationsSelectize.refreshOptions(false)
        checkSelectionButtons()
        checkContinue()

    sortBy = (key, a, b, r) ->
        r = if r then 1 else -1
        return -1 * r if a[key] > b[key]
        return +1 * r if a[key] < b[key]
        return 0

    sortByMultiple = (a, b, keys) ->
        return r if (r = sortBy key, a, b) for key in keys
        return 0


    $deSelectAll.on 'click', ->
        possibleCultivations = Object.values(cultivationsSelectize.options)
        for p in possibleCultivations
            cultivationsSelectize.removeItem(p.value, true)
        $('div.active').removeClass('active')
        cultivationsSelectize.refreshOptions(false)
        checkSelectionButtons()
        checkContinue()

    onFilterChange()
    checkContinue()

register('App.DataTransfer.ZoneMapFilter.Setup', setup)
