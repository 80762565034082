{register} = require 'src/js/infrastructure/setup-registry'

setup = ($ctx) ->
    $button = $ctx.find('#submitSelectMap')
    $selectAll = $ctx.find('#select-all')
    $deSelectAll = $ctx.find('#deselect-all')

    $years = $ctx.find('#year')
    $orgUnits = $ctx.find('#orgUnits')
    $cropTypes = $ctx.find('#cropTypes')
    $parcels = $ctx.find('#parcels')

    yearsSelectize = $years[0].selectize
    orgUnitsSelectize = $orgUnits[0].selectize
    cropTypesSelectize = $cropTypes[0].selectize
    parcelsSelectize = $parcels[0].selectize

    allYears = Object.values yearsSelectize.options
    allOrgUnits = Object.values orgUnitsSelectize.options
    allCropTypes = Object.values cropTypesSelectize.options
    allParcels = Object.values parcelsSelectize.options

    selectedYears = []
    selectedOrgUnits = []
    selectedCropTypes = []

    filterYearsAndCropTypes = (selection) ->
        if not selectedYears and not selectedCropTypes
            return true

        if selectedYears
            for year in selectedYears
                for yearAndCropType in selection.parcelinfo.yearsWithCropType
                    if year is yearAndCropType.year
                        if not selectedCropTypes or selectedCropTypes.indexOf(yearAndCropType.cropTypeId) > -1
                            return true
        if selectedCropTypes
            for cropTypeId in selectedCropTypes
                for yearAndCropType in selection.parcelinfo.yearsWithCropType
                    if cropTypeId is yearAndCropType.cropTypeId
                        if not selectedYears or selectedYears.indexOf(yearAndCropType.year) > -1
                            return true

        return false

    filterByYear = (selection) ->
        if not selectedYears
            return true

        yearsAndCropType = selection.parcelinfo.yearsWithCropType
        for yearAndCropType in yearsAndCropType
            if selectedYears.indexOf(yearAndCropType.year) > -1
                return true
        return false

    filterByCropType = (selection) ->
        if not selectedCropTypes
            return true

        yearsAndCropType = selection.parcelinfo.yearsWithCropType
        for yearAndCropType in yearsAndCropType
            if selectedCropTypes.indexOf(yearAndCropType.cropTypeId) > -1
                return true
        return false

    filterByOrgUnit = (selection) ->
        not selectedOrgUnits or selectedOrgUnits.indexOf(selection.parcelinfo.companyId) > -1

    filterByAll = (selection) ->
        filterByYear(selection) and filterByOrgUnit(selection) and filterByCropType(selection)

    updateSelectizeOptions = (selectize, newOptions) ->
        newOptionsLookup = {}
        for option in newOptions
            newOptionsLookup[option.value] = option

        added = newOptions.filter (x) -> not selectize.options[x.value]
        removed = Object.values(selectize.options).filter (existingOption) -> not newOptionsLookup[existingOption.value]

        for option in added
            selectize.addOption option
        for option in removed
            selectize.removeOption option.value

        selectize.settings.sortField = 'text'

    distinct = (x, i, arr) -> arr.indexOf(x) is i

    updateFilterOptions = ->
        availableYears = []
        availableParcels = allParcels.filter (x) -> filterByOrgUnit(x) and filterByCropType(x)
        for parcel in availableParcels
            years = parcel.parcelinfo.yearsWithCropType.map (x) -> x.year
            for year in years
                availableYears.push year
        availableYears = availableYears.filter distinct

        filteredYears = allYears.filter (x) -> availableYears.indexOf(x.value) > -1
        updateSelectizeOptions(yearsSelectize, filteredYears)

        availableOrgUnits = allParcels.filter (x) -> filterByYear(x) and filterByCropType(x)
        .map (x) -> x.parcelinfo.companyId
        .filter distinct

        filteredOrgUnits = allOrgUnits.filter (x) -> availableOrgUnits.indexOf(x.value) > -1
        updateSelectizeOptions(orgUnitsSelectize, filteredOrgUnits)

        availableCropTypes = []
        availableParcels = allParcels.filter (x) -> filterByYear(x) and filterByOrgUnit(x)
        for parcel in availableParcels
            cropTypes = parcel.parcelinfo.yearsWithCropType.map (x) -> x.cropTypeId
            for cropTypeId in cropTypes
                availableCropTypes.push cropTypeId

        availableCropTypes = availableCropTypes.filter distinct

        filteredCropTypes = allCropTypes.filter (x) -> availableCropTypes.indexOf(x.value) > -1
        updateSelectizeOptions(cropTypesSelectize, filteredCropTypes)

    checkSelectionButtons = ->
        options = Object.values $parcels[0].selectize.options
        if $orgUnits.val() or $years.val() or $cropTypes.val()
            $selectAll.enable()
        else
            $selectAll.disable()

        if $parcels.val()
            $deSelectAll.enable()
            if options.length is $parcels.val().length
                $selectAll.disable()
        else
            $deSelectAll.disable()

    onFilterChange = ->
        selectedYears = $years.val()
        if selectedYears
            selectedYears = selectedYears.map (x) -> parseInt x

        selectedOrgUnits = $orgUnits.val()
        selectedCropTypes = $cropTypes.val()

        updateSelectizeOptions parcelsSelectize, allParcels.filter (x) -> filterYearsAndCropTypes(x) and filterByOrgUnit(x)
        updateFilterOptions()
        checkSelectionButtons()

    $years.on 'change', onFilterChange
    $orgUnits.on 'change', onFilterChange
    $cropTypes.on 'change', onFilterChange

    checkContinue = ->
        unless $('#parcels').val()
            $button.disable()
        else
            $button.enable()

    $parcels.on 'change', ->
        checkContinue()
        checkSelectionButtons()

    yearsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    orgUnitsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    cropTypesSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')

    parcelsSelectize.on 'item_add', (value, data) ->
        $("div[data-value=\"#{value}\"]").removeClass('active')
        parcelsSelectize.settings.sortField = 'text'

    $selectAll.on 'click', ->
        possibleParcels = Object.values(parcelsSelectize.options)
        possibleParcels.sort (a, b) -> sortByMultiple a, b, ['text']
        for p in possibleParcels
            parcelsSelectize.addItem(p.value, true)
        $('div.active').removeClass('active')
        parcelsSelectize.settings.sortField = 'text'
        parcelsSelectize.refreshOptions(false)
        checkSelectionButtons()
        checkContinue()

    $deSelectAll.on 'click', ->
        possibleParcels = Object.values(parcelsSelectize.options)
        for p in possibleParcels
            parcelsSelectize.removeItem(p.value, true)
        $('div.active').removeClass('active')
        parcelsSelectize.refreshOptions(false)
        checkSelectionButtons()
        checkContinue()

    sortBy = (key, a, b, r) ->
        r = if r then 1 else -1
        return -1 * r if a[key] > b[key]
        return +1 * r if a[key] < b[key]
        return 0

    sortByMultiple = (a, b, keys) ->
        return r if (r = sortBy key, a, b) for key in keys
        return 0
    onFilterChange()
    checkContinue()

register('App.DataTransfer.ParcelFilter.Setup', setup)
