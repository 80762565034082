# CoffeeScript

{createJqueryPlugin} = require 'src/js/helpers/create-jquery-plugin'

{LayerManager} = require './layer-manager'
mapservers = require './servers'
{getGeodesicLength, getGeodesicArea, getGeodesicDistance} = require './utilities/coordinates'

{Toolbar} = require './controls/toolbar'
{SlideOutMenu} = require './controls/slide-out-menu'
{createGeotrackingControl} = require './geotracking'

{Plugin} = require './plugins/base'
{InfoClickPlugin} = require './plugins/info-click'
{MeasurementPlugin} = require './plugins/measurement'
{EditingHelpPlugin} = require './webgis/plugins/editing-help'

{WebGIS} = require './webgis'
{AddPointsPlugin, AddLinesPlugin, AddPolygonsPlugin} = require './webgis/plugins/add-geometry'

Map = require('ol/Map').default
Overlay = require('ol/Overlay').default
View = require('ol/View').default
Image = require('ol/layer/Image').default
ImageWMS = require('ol/source/ImageWMS').default
ScaleLine = require('ol/control/ScaleLine').default
ZoomToExtent = require('ol/control/ZoomToExtent').default
Zoom = require('ol/control/Zoom').default
ZoomSlider = require('ol/control/ZoomSlider').default

bingAttribution = '<a href="http://www.bing.com/">Bing</a>'
defaultAttribution =  '<a href="https://ixmap.eu/">iXmap Services GmbH &amp; Co. KG</a>'
geoFachDatenAttribution = '<a href="http://www.brain-scc.de/de/geofachdatenserver.html">GeoFachDatenServer Sachsen-Anhalt</a>'

class MapControl
    # TODO: ensure that @$element is a jquery element
    constructor: (element, @options) ->
        @$target = $ element

        @options.promises.addedToDom = @options.promises.addedToDom.then => @reset()

        @_setupModal()

        @_createMap()

        @plugins = []

        @map.getView().fit @options.extent

        @_addAdditionalControls()
        @_addAttributionPopover()

        if @layerManager.groups.webgis.getLayers().getArray().length
            @_addWebGIS()

        for plugin in @options.plugins
            @registerPlugin plugin

        # fix for map in tabs
        $tabPane = @$target.closest('.tab-pane')
        if $tabPane.length
            tabId = $tabPane.attr 'id'
            $tabHeader = $tabPane.parent().siblings('.nav.nav-tabs').find("a[href='##{tabId}']").parent()
            $tabHeader.on 'shown.bs.tab', =>
                @reset()
                $tabHeader.off 'shown.bs.tab'

    fitMap: (extent) ->
        @options.extent = extent
        @map.getView().fit @options.extent, {duration: 2000}

    reset: =>
        @$target.closest('.map-panel')
        .addClass 'in'
        .prev()
        .addClass 'hidden'
        @map.updateSize()
        @map.getView().fit @options.extent

    _setupModal: ->
        $container = @$target.parent()
        @$modal = @$target.closest('.mapcontrol-wrapper').find ".map-extent-modal"
        @$modal.on 'show.bs.modal', (event) =>
            @$modal.find('.map-content').append @$target
            height = $(window).height() - 150
            @$target.css 'height', height
            @$modal.find('.modal-body').css 'height', height
        @$modal.on 'shown.bs.modal', =>
            @map.updateSize()
            @$modal.modal('handleUpdate')
        @$modal.on 'hide.bs.modal', =>
            $container.prepend @$target
        @$modal.on 'hidden.bs.modal', =>
            @$target.css 'height', ""
            @$modal.find('.modal-body').css 'height', ""
            @map.updateSize()

    registerPlugin: (plugin) ->
        unless plugin instanceof Plugin
            throw 'plugin for map has to be instance of Controls.Map.MapPlugin'

        @plugins.push plugin
        plugin.mapcontrol = @
        plugin.map = @map
        plugin.init @options

    _createMap: ->
        extent = @options.extent
        xCenter = extent[0] + (extent[2] - extent[0]) / 2
        yCenter = extent[1] + (extent[3] - extent[1]) / 2

        defaultView = new View
            projection: 'EPSG:900913'
            center: [xCenter, yCenter]
            zoom: 15
            minZoom: 1
            maxZoom: @options.maxZoom

        @toolbar = new Toolbar
        @slideoutmenu = new SlideOutMenu

        availableControls = [
            new ScaleLine()
            @toolbar
            @slideoutmenu
        ]

        if @options.features.zoom
            @extentControl = new ZoomToExtent
                extent: @options.extent
                tipLabel: @options.tooltips.zoomToExtent
            availableControls.push @extentControl
            availableControls.push new Zoom
                zoomInTipLabel: @options.tooltips.zoomIn
                zoomOutTipLabel: @options.tooltips.zoomOut
            availableControls.push new ZoomSlider

        @map = new Map
            target: @$target[0]
            # renderer: 'webgl'
            #layers: baseLayers
            projection: 'EPSG:900913'
            view: defaultView
            controls: availableControls

        @layerManager = new LayerManager this

        # add builtin layers

        @layerManager.addBingLayer 'Aerial', 'Satellitenbilder'
        @layerManager.addBingLayer 'AerialWithLabels', 'Hybrid', visible: true
        @layerManager.addBingLayer 'Road', 'Straßenkarte'

        uavLayer =
            layer: 'agrosat:uav'
            layerDisplayName: 'UAV-Aufnahmen'
            namespace: 'agrosat'
            visible: false

        @layerManager.addGeoserverLayer uavLayer,
            group: 'drone'

        gfdsSachsenAnhaltUrl = 'http://www.gfds.sachsen-anhalt.de/ows/ws/wms/324f9ca5-49cb-ed92/GDI-LSA_LAGB_BODEN_SCHAETZUNG_PARAM/ows.wms'

        externalLayers = [
            {
                layer: 'external:ST_nutzbare_Feldkapazitaet'
                layerDisplayName: 'nutzbare Feldkapazität (ST)',
                namespace: 'external',
                visible: false,
                legendUrl: gfdsSachsenAnhaltUrl,
                legendLayerName: 'nutzbare_Feldkapazitaet',
                attribution: 'provided by <a href="http://www.brain-scc.de/de/geofachdatenserver.html">GeoFachDatenServer Sachsen-Anhalt</a>'
            },
            {
                layer: 'external:ST_Bodenart_Oberboden'
                layerDisplayName: 'Bodenart Oberboden (ST)',
                namespace: 'external',
                visible: false,
                legendUrl: gfdsSachsenAnhaltUrl,
                legendLayerName: 'Bodenart_Oberboden',
                attribution: 'provided by <a href="http://www.brain-scc.de/de/geofachdatenserver.html">GeoFachDatenServer Sachsen-Anhalt</a>'
            }
        ]

        if @options.layerOverrides.showGroundLayer
            externalLayers.push
                layer: 'agrosat:Bodenlayer_Deutschland'
                layerDisplayName: 'Bodenlayer Deutschland'
                namespace: 'agrosat'
                visible: false

        for layer in externalLayers
            @layerManager.addGeoserverLayer layer,
                group: 'external'

        # add layers from options

        for vectorLayer in @options.layers.vector ? []
            @layerManager.addVectorLayer vectorLayer

        @_addWmsMapLayers()

        for layer in @options.layers.geoserver ? []
            @layerManager.addGeoserverLayer layer

    _addWmsMapLayers: (wmsLayers) ->
        for layer in (x for x in @options.layers.external.split ';' when x)
            @layerManager.groups.external.getLayers().push @_defineExternalWmsLayer layer, @options.attributionCreatedBy

        for layer in (x for x in @options.layers.secondary.split ';' when x)
            args = [
                layer
                @options.servers.secondary
                true
            ]
            layer = @_defineWmsLayer args...
            @layerManager.groups.satellite.getLayers().push layer

        for layer in (x for x in @options.layers.primary.split ';' when x)
            separator = @options.servers.primary.firstOptionSeparator
            args = [
                layer
                @options.servers.primary
                @options.servers.primary.url isnt mapservers.local.url
            ]
            layer = @_defineWmsLayer args...
            @layerManager.groups.data.getLayers().push layer

    _addAdditionalControls: ->
        @$target.find '.ol-zoom-extent button'
        .empty()
        .append $ '<i class="icon icon-ix-crosshairs">'

        @$target.parent().find '.mapInfoClickButton'
        .appendTo @toolbar.$element
        @$target.parent().find '.mapPrintButton'
        .hide()

       #todo we have canvas secutiy isssues since in our canvas are imgaes sources from other servers
       # @$target.parent().find '.mapPrintButton'
       # .attr 'title', @options.tooltips.print
       # .appendTo @toolbar.$element
       # .on 'click', =>
       #     @map.once 'postcompose', (event) =>
       #         canvas = event.context.canvas
       #         imageUrl = canvas.toDataURL 'image/png'
       #         w = window.open()
       #         $(w.document.body).append($('<img>').attr('src',imageUrl))
       #     @map.renderSync()

        @$target.parent().find '.mapGeoTrackingButton'
        .appendTo @toolbar.$element

        @$target.parent().find '.mapMeassure'
        .appendTo @toolbar.$element

        $expandButton = @$target.parent().find '.mapExpandButton'

        $expandButton
        .appendTo @toolbar.$element
        .attr 'title', @options.tooltips.expand
        .on 'click', =>
            $($expandButton.attr 'data-target').modal('toggle')
            $expandButton.children('i').toggleClass('icon icon-ix-expand').toggleClass('fa fa-compress')
            if $expandButton.children('i').hasClass 'icon-ix-expand'
                $expandButton.attr 'title', @options.tooltips.expand
            else
                $expandButton.attr 'title', @options.tooltips.collapse

        if @options.features.geotracking
            createGeotrackingControl @map
        else
            @toolbar.$element.find('.mapGeoTrackingButton').detach()

        if @options.features.measurement
            @registerPlugin new MeasurementPlugin
            #olCustomControls.Measurement.control @map
        else
            @toolbar.$element.find('.mapMeassure').detach()

        if @options.features.infoClick
            $container = @$target.parent().find '#info-popup'
            $closer = @$target.parent().find '#info-popup-closer'

            $closer.click ->
                $container.hide()
                $closer.blur()
                return false

            overlay = new Overlay
#                stopEvent: false
                element: $container[0]

            @map.addOverlay overlay
            @registerPlugin new InfoClickPlugin overlay
        else
            @toolbar.$element.find('.mapInfoClickButton').detach()

    _addAttributionPopover: ->
        collectAttributions = =>
            html = '<ul class="attribution-list">'

            for layer in @layerManager.allLayers()
                continue unless layer.getVisible()
                html += '<li><h5 class="layer-name">' + layer.get('name') + '</h5></li>'
            html += '</ul>'

            html += '<div><span class="layer-attributions small">Powered by:</span></div>'
            html += '<div><span class="layer-attributions small">' + bingAttribution + ' | ' + defaultAttribution + ' | ' + geoFachDatenAttribution + '</span></div>'
            return html

        $attributionButton = $ '<button class="attribution-button">'
        .append $ '<i class="fa fa-copyright">'
        .appendTo @toolbar.$element
        .popover
            content: collectAttributions
            html: true
            placement: 'top'
            container: 'body'
            trigger: 'hover'
        .on('show.bs.popover', -> $(this).data('bs.popover').tip().css(maxWidth: '450px'))

    _addWebGIS: ->
        @_setUpWebGisOptions()
        @webgis = new WebGIS this, @layerManager.layers.webgis(), @options.editing
        @$target.addClass 'webgis'

    _setUpWebGisOptions: ->
        @options.editing or= {}
        @options.editing.plugins or= []
        @options.editing.tooltips = @options.tooltips.webgis
        geoTypes = []
        for layer in @layerManager.layers.webgis()
            if layer.get 'canAdd'
                type = layer.get 'geometryType'
                maxFeatures = layer.get('numberOfAllowedFeatures')
                name = layer.get('name')

                pluginType = switch type
                    when 'Point' then AddPointsPlugin
                    when 'Line' then AddLinesPlugin
                    when 'Polygon' then AddPolygonsPlugin
                if pluginType
                    @options.editing.plugins.push new pluginType name, maxFeatures, @options.tooltips.webgis

        if @layerManager.layers.webgis().some((x) -> x.get('canAdd'))
            @options.editing.plugins.push new EditingHelpPlugin

    _defineWmsLayer: (layerString, server, fcgi) ->
        [
            name
            displayName
            parameter
            visibility
        ] = layerString.split ':'
        displayName or= name

        parameter = parameter.replace(/!/g, '&').replace(/\\/g, '/')
        visibility = (visibility ? 'true').toLowerCase() is 'true'

        url = server.url + server.appName
        if fcgi
            url += '.fcgi'
        url += server.firstOptionSeparator
        url += 'EXCEPTIONS=INIMAGE'

        if parameter
            url += '&' + parameter

        attribution = """
            Layer: <b>#{displayName}</b> #{@options.attributionCreatedBy} \
            <a href='http://www.ixmap.eu'>iXmap</a><br/>
        """

        return new Image
            source: new ImageWMS
                url: url
                serverType: 'mapserver'
                params: {'LAYERS': name, "FORMAT": 'image/png'}
                attributions: [attribution]
                crossOrigin: 'anonymous'
            visible: visibility
            name: displayName

    _defineExternalWmsLayer: (layer) ->
        [
            name
            displayName
            url
            port
            isHttps
            parameter
            format
            visibility
            href
            hrefLabel
        ] = layer.split ':'

        displayName or= name
        format or= 'image/png'
        visibility = (visibility ? 'true').toLowerCase() is 'true'
        isHttps = isHttps?.toLowerCase() is 'true'

        url = 'http' + (if isHttps then 's' else '') + '://' + url

        if port
            url += ':' + port

        url += parameter

        attribution = """
            Layer: <b>#{displayName}</b> #{@options.attributionCreatedBy} \
            <a href='http://#{href}'>#{hrefLabel}</a><br/>
        """

        layer = new Image
            source: new ImageWMS
                url: url
                params: {'LAYERS': name, 'FORMAT': format}
                attributions: [attribution]
            visible: visibility
        layer.set 'name', displayName
        return layer

    changeDefaultExtent: (extent) ->
        @options.extent = extent
        @map.removeControl(@extentControl)
        @extentControl = new ZoomToExtent
            extent: @options.extent
            tipLabel: @options.tooltips.zoomToExtent
        @map.addControl(@extentControl)
        @$target.find '.ol-zoom-extent button'
        .empty()
        .append $ '<i class="icon icon-ix-crosshairs">'

    addMapServerLayer: (layerString, name) ->
        args = [
            layerString
            @options.servers.primary
            @options.servers.primary.url isnt mapservers.local.url
        ]
        layer = @_defineWmsLayer args...
        layer.set 'name', name

        @layerManager.groups.data.getLayers().push layer

    removeLayerByName: (name) ->
        for group in @layerManager.allLayerGroups
            for layer in group.getLayers().getArray()
                if layer and layer.get('name') is name
                    group.getLayers().remove layer

    clearMapServerLayer: ->
        @layerManager.groups.data.getLayers().clear()

    getFeatureDistance: (feature1, feature2) ->
        c1 = feature1.getGeometry().getCoordinates()
        c2 = feature2.getGeometry().getCoordinates()
        return getGeodesicDistance @map, c1, c2

    getFeatureLength: (feature) ->
        line = feature.getGeometry()
        return getGeodesicLength @map, line

    getFeatureArea: (feature) ->
        polygon = feature.getGeometry()
        return getGeodesicArea @map, polygon

createJqueryPlugin 'mapcontrol', MapControl

getMapsByName = (name, $ctx) ->
    if $ctx
        $map = $ctx.find ".mapcontrol##{name}"
    else
        $map = $ ".mapcontrol##{name}"
    $map.mapcontrol()

getByName = (name, $ctx) ->
    getMapsByName(name, $ctx)[0]

module.exports = {
    getMapsByName,
    getByName
}
