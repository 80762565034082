<template>
  <!-- atm it's class is row, in order to be compatible with the old datatransfer -->
  <div class="row">
    <!-- disabled, will only be changed by jquery within the old datatransfer atm -->
    <button
      type="button"
      disabled
      class="btn btn-default btn-block send-to-button navbar-btn col-xs-12"
      @click.prevent="openModal()"
    >
      <span class="button-content-container">
        <i class="fa fa-share" />
        <!-- currently missing a logo -->
        <span>OSB</span>
      </span>
    </button>
    <BsModal v-model="isModalActive" :inline="false">
      <IxRes slot="title">osb.createTask</IxRes>
      <div>
        <div>
          <VueSelectize
            v-model="selectedBox" :options="boxOptions"
            :default="boxOptions[0]"
            :allow-empty="boxes.length == 0" required
          >
            <IxRes slot="label">osb.box</IxRes>
          </VueSelectize>
        </div>
        <div>
          <TextInput v-model="task.name" required>
            <IxRes slot="label">osb.task.name</IxRes>
          </TextInput>
        </div>
        <div>
          <TextInput v-model="task.description" multiline>
            <IxRes slot="label">osb.task.description</IxRes>
          </TextInput>
        </div>
        <div class="row">
          <div class="col-md-6">
            <VueDateTimePicker
              :key="'validFromPicker'" v-model="task.validFrom"
              iso
              required
            >
              <IxRes slot="label">osb.task.validFrom</IxRes>
            </VueDateTimePicker>
          </div>
          <div class="col-md-6">
            <VueDateTimePicker
              :key="'validToPicker'" v-model="task.validTo"
              iso
              :min="task.validFrom"
            >
              <IxRes slot="label">osb.task.validTo</IxRes>
            </VueDateTimePicker>
          </div>
        </div>
      </div>
      <div slot="footer">
        <IxButton cancel large @click="isModalActive = false" />
        <IxButton
          large save
          :disabled="!canCreateTask"
          @click="createTask()"
        >
          <IxRes>osb.createTask</IxRes>
        </IxButton>
      </div>
    </BsModal>
    <TranslationControls />
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {notifications} from 'src/js/infrastructure'
import IxButton from '@components/IxButton'
// import VueDateTimeRangePicker from '@components/VueDateTimeRangePicker' // removed because layouting is impractical atm
import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import BsModal from '@components/bootstrap/BsModal'
import TranslationControls from '@components/translation/TranslationControls'

function createDefaultTask () {
  return {
    boxId: null,
    applicationMapIds: [],
    name: null,
    description: null,
    validFrom: moment(),
    validTo: null
  }
}

export default {
  components: {
    IxButton,
    VueSelectize,
    TextInput,
    VueDateTimePicker,
    BsModal,
    TranslationControls
  },
  data () {
    return {
      isModalActive: false,
      boxes: [],
      task: createDefaultTask()
    }
  },
  computed: {
    boxOptions () {
      return this.boxes.map(box => {
        return {value: box.id, text: box.id, label: `v${box.version}`}
      })
    },
    selectedBox: {
      get () {
        if (this.boxOptions.length === 0 || this.task.boxId === null) return null

        return this.boxOptions.find(option => option.value === this.task.boxId)
      },
      set (option) {
        this.task.boxId = option.value
      }
    },
    canCreateTask () {
      return this.task.boxId &&
        this.task.applicationMapIds.length > 0 &&
        this.task.name &&
        this.task.validFrom
    }
  },
  methods: {
    openModal () {
      const applicationMapIds = $('.datatransfer-selection-panel').find('input[type="checkbox"].mapcheckbox:checked').toArray().map(e => $(e).data('id'))

      this.task.applicationMapIds = applicationMapIds
      this.isModalActive = true
    },
    createTask () {
      axios.post('/OSB/CreateTask', this.task)
        .then(() => notifications.success('osb.taskCreated'))
        .then(() => {
          this.isModalActive = false
          this.task = createDefaultTask()
        })
        .catch(() => notifications.error('osb.taskCreationFailed'))
    }
  },
  beforeCreate () {
    axios.get('/OSB/GetBoxes')
      .then((response) => {
        this.boxes = response.data
      })
      .then(() => {
        this.task.boxId = this.boxes[0].id
      })
  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Messages.Info.SR_InfoMessages',
      'osb'
    ])
  }
}
</script>
<style lang="scss" scoped>
.date-range-container {
  display: flex;
  width: 100%;
}
</style>
