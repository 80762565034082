{register} = require 'src/js/infrastructure/setup-registry'
{post, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

{getModal} = require 'src/coffee/controls/modals'

loginSetup = ->
    $('#login').on 'click', ->
        formValid = $('#login-form').valid()
        if formValid
            $('#login-modal').modal('hide') #TODO: Is this needed?
            url = $('#login-form').data 'url'

            data =
                username: $('#user-name').val()
                password: $('#password').val()
                RememberMe: $('#remember-me').prop('checked')

            promise = getModal url, data, post
            waitscreen.waitFor promise

organizationSetup = ($ctx) ->
    $logoutBtn = $ctx.find('#logout')
    $logoutBtn.on 'click', ->
        $('#organizations').modal('hide')
        url = $logoutBtn.data 'url'
        promise = getModal  url
        waitscreen.waitFor promise

    $ctx.find('#confirm-org-unit').on 'click', ->
        formValid = $('#org-unit-form').valid()
        if formValid
            $('#organizations').modal('hide')
            url = $('#org-unit-form').data 'url'

            data =
                OrganizationId: $('#org-units').val()
                UserToken: $('#UserToken').val()
                Username: $('#UserName').val()
                Password: $('#Password').val()

            getModal url, data, post


machineIdsSetup = ($ctx) ->
    $orgBtn = $ctx.find('#org-units')
    $orgBtn.on 'click', ->
        $('#machine-ids').modal('hide')
        url = $orgBtn.data 'url'
        data =
            UserToken: $('#UserToken').val()
        promise = getModal  url, data
        waitscreen.waitFor promise

    $logoutBtn = $ctx.find('#logout')

    $logoutBtn.on 'click', ->
        $('#machine-ids').modal('hide')
        url = $logoutBtn.data 'url'

        promise = getModal  url
        waitscreen.waitFor promise

    $sendButton = $ctx.find('#confirm-devices')
    $sendButton.on 'click', ->
        formValid = $('#devices-form').valid()
        if formValid
            allApplicationMaps = $('input.mapcheckbox')

            selectedApplicationMapIds = []
            for appMap in allApplicationMaps
                $appMap = $ appMap
                if appMap.checked
                    selectedApplicationMapIds.push($appMap.data 'id')
            data =
                selectedApplicationMapIds: selectedApplicationMapIds
                applicationFileName: $('#application-file-name').val()
                orgToken: $('#OrganizationToken').val()
                machineIds: $('#devices').val()

            url = $('#devices-form').data 'url'

            if $('#devices-form').valid()
                $('#machine-ids').modal('hide')

                promise = antiForgeryPost url, data
                    .done makeSuccessHandler $sendButton
                    .fail makeErrorHandler $sendButton

                waitscreen.waitFor promise

register('App.DataTransfer.Trimble.LoginSetup', loginSetup)
register('App.DataTransfer.Trimble.OrganizationSetup', organizationSetup)
register('App.DataTransfer.Trimble.MachineIdsSetup', machineIdsSetup)
