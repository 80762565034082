{register} = require 'src/js/infrastructure/setup-registry'

{getByName} = require 'src/coffee/controls/map'

setup = ($ctx) ->
    $ctx.find('button.map-preview').on 'click', mapPreview
    $ctx.find('button.parcel-preview').on 'click', parcelPreview
    $ctx.find('button#previewSelected').on 'click', previewSelection
    $ctx.find('#mapModal').on 'shown.bs.modal', refreshMap

refreshMap = ->
    map = getByName 'map'
    setTimeout map.reset, 100

mapPreview = (event) ->
    button = event.currentTarget

    layer = button.dataset.layer
    layerName = button.dataset.layerName

    extent = $(button).closest('.parcel-panel').data 'extent'
    extentArray = [extent.xMin, extent.yMin, extent.xMax, extent.yMax]

    updateMap extentArray, [{layer, layerName}]

parcelPreview = (event) ->
    $button = $ event.currentTarget
    extent = $button.closest('.parcel-panel').data 'extent'
    extentArray = [extent.xMin, extent.yMin, extent.xMax, extent.yMax]
    $checkedMapButtons = $button
        .closest('.parcel-panel')
        .find('input:checked')
        .closest('.checkbox')
        .find('button.map-preview')

    appMaps = for button in $checkedMapButtons
        layer: button.dataset.layer
        layerName: button.dataset.layerName

    updateMap extentArray, appMaps

previewSelection = ->
    $checkedMapButtons = $('input:checked').closest('.checkbox').find('button.map-preview')
    $parcelDivs = $('input:checked').closest('.parcel-panel')
    parcelExtents = []

    for parcelDiv in $parcelDivs
        parcelExtents.push $(parcelDiv).data('extent')

    appMaps = for button in $checkedMapButtons
        layer: button.dataset.layer
        layerName: button.dataset.layerName

    extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
    if parcelExtents.length is 0
        defualtExtent = $('#defaultExtent').data 'extent'
        extent = [defualtExtent.xMin, defualtExtent.yMin, defualtExtent.xMax, defualtExtent.yMax]

    for pe in parcelExtents
        do (pe) ->
            extent[0] = if extent[0] > pe.xMin then pe.xMin else extent[0]
            extent[1] = if extent[1] > pe.yMin then pe.yMin else extent[1]
            extent[2] = if extent[2] < pe.xMax then pe.xMax else extent[2]
            extent[3] = if extent[3] < pe.yMax then pe.yMax else extent[3]

    updateMap extent, appMaps


updateMap = (extent, appMaps) ->
    map = getByName 'map'
    map.clearMapServerLayer()
    map.changeDefaultExtent extent

    for appMap in appMaps
        do (appMap) ->
            map.addMapServerLayer appMap.layer, appMap.layerName
    $('#mapModal').modal('show')

register('App.DataTransfer.Preview.Setup', setup)
