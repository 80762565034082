# CoffeeScript

{Plugin} = require './base'

Style = require('ol/style/Style').default
Stroke = require('ol/style/Stroke').default
Circle = require('ol/style/Circle').default
Fill = require('ol/style/Fill').default
Text = require('ol/style/Text').default
Snap = require('ol/interaction/Snap').default
Draw = require('ol/interaction/Draw').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

fill = new Fill
    color: 'rgba(255, 255, 255, 0.2)'
textFill = new Fill
    color: '#ffffff'
stroke = new Stroke
    color: '#ffcc33'
    width: 2
textStroke = new Stroke
    color: '#ff0000'
    width: 3
image = new Circle
    radius: 7
    fill: new Fill
        color: '#ffcc33'

style = (feature, resolution) ->
    return [
        new Style
            fill: fill
            stroke: stroke
            image: image
            text: new Text
                textAlign: 'center'
                textBaseline: 'middle'
                font: 'bold 12px Courier New'
                text: feature.get 'label'
                fill: textFill
                stroke: textStroke
                offsetX: 0
                offsetY: 0
                rotation: 0
    ]

class MeasurementPlugin extends Plugin
    init: ->
        @$button = @mapcontrol.$target.parent().find '.mapMeasureButton'
            .on 'click', @startMeasurement
        @$clearButton = @mapcontrol.$target.parent().find '.mapRemoveMeasureButton'
            .on 'click', @clearMeasurements

        @$clearButton.hide()

        @source = new VectorSource
        @overlay = new VectorLayer
            map: @map
            source: @source
            style: style

    cancelDoubleClickZoom: (event) ->
        event.preventDefault()

    formatLength: (length) ->
        length = Math.round(length*100)/100
        if length >= 100
            return Math.round(length / 1000 * 100) / 100 + ' km'
        else
            return Math.round(length * 100) / 100 + ' m'

    startMeasurement: =>
        @draw = new Draw
            source: @source
            type: 'LineString'

        @draw.on 'drawend', @endMeasurement
        @map.on 'dblclick', @cancelDoubleClickZoom

        @map.addInteraction(@draw)

        @snap = new Snap
            source: @source
        @map.addInteraction(@snap)

    endMeasurement: (event) =>
        feature = event.feature
        length = @mapcontrol.getFeatureLength feature
        feature.set 'label', @formatLength length


        @map.removeInteraction(@draw)
        @map.removeInteraction(@snap)
        setTimeout => @map.un 'dblclick', @cancelDoubleClickZoom

        @$clearButton.show()

    clearMeasurements: =>
        @source.clear()
        @$clearButton.hide()

module.exports = {
    MeasurementPlugin
}

module.exports.__esModule = true
module.exports.default = MeasurementPlugin
