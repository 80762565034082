# CoffeeScript

Control = require('ol/control/Control').default

class Menu extends Control
    $activeContent: null

    constructor: (options) ->
        @options = $.extend {}, options

        @$element = $ '<div class="ol-slide-out-menu ol-unselectable ol-control">'
        @$buttons = $ '<div class="slide-out-buttons">'
        .appendTo @$element
        @$popout = $ '<div class="slide-out-content collapsed">'
        .appendTo @$element

        Control.call this,
            element: @$element[0]
            target: @options.target

    addEntry: ($button, $content) ->
        @$buttons.append $button
        @$popout.append $content
        $content.addClass 'hidden'
        $content.addClass 'fade'

        $content.on 'resize', @recalculateSize

        active = false
        $button.on 'click', =>
            @$popout.children().addClass 'hidden'
            @$popout.children().removeClass 'in'
            @$buttons.children().removeClass 'active'

            if @$activeContent is $content
                @$popout.addClass 'collapsed'
                @$activeContent = null
            else
                $content.removeClass 'hidden'
                setTimeout (-> $content.addClass 'in'), 1
                $button.addClass 'active'
                @$popout.removeClass 'collapsed'

                $content.trigger 'show'

                for img in $content.find('img')
                    img.onload = @recalculateSize
                setTimeout @recalculateSize, 200

                @$activeContent = $content
            return false

    recalculateSize: =>
        return unless @$activeContent
        @$popout.css 'height', @$activeContent.height() + 25

module.exports = {
    SlideOutMenu: Menu
}

module.exports.__esModule = true
module.exports.default = Menu
