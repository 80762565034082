# CoffeeScript

{getStyle, defineStyle} = require './utilities/styling'
mapservers = require './servers'

#import globalize from 'globalize'

WKT = require('ol/format/WKT').default
Style = require('ol/style/Style').default
Fill = require('ol/style/Fill').default
VectorLayer = require('ol/layer/Vector').default
Group = require('ol/layer/Group').default
Tile = require('ol/layer/Tile').default
Image = require('ol/layer/Image').default
XYZ = require('ol/source/XYZ').default
TileWMS = require('ol/source/TileWMS').default
BingMaps = require('ol/source/BingMaps').default
Vector = require('ol/source/Vector').default
Cluster = require('ol/source/Cluster').default
VectorSource = require('ol/source/Vector').default

{config} = require 'src/js/infrastructure'

mapboxAttribution = 'provided by <a href="http://www.mapbox.com/">Mapbox</a>'
bingAttribution = 'provided by <a href="http://www.bing.com/">Bing</a>'
geoserverAttribution = 'created by <a href="http://www.ixmap.eu/">iXmap</a>'
mapserverAttribution = 'created by <a href="http://www.ixmap.eu/">iXmap</a>'
vectorAttribution = 'created by <a href="http://www.ixmap.eu/">iXmap</a>'

class LayerManager
    constructor: (@mapcontrol) ->
        @map = @mapcontrol.map

        groups = [
            ['base', new Group]
            ['drone', new Group]
            ['satellite', new Group opacity: 1]
            ['climate', new Group opacity: 0.5]
            ['external', new Group opacity: 0.5]
            ['data', new Group opacity: 0.9]
            ['utilities', new Group opacity: 0.5]
            ['vector', new Group]
            ['webgis', new Group]
        ]

        @layers = {}

        @groups = {}

        @allLayerGroups = []
        @allLayers = -> [].concat @allLayerGroups.map((x) -> x.getLayers().getArray())...

        for [name, group], i in groups
            do (name, group, i) =>
                @allLayerGroups.unshift group
                @groups[name] = group
                @layers[name] = -> group.getLayers().getArray()

                group.setZIndex i * 100
                group.set 'name', name

                @map.addLayer group

    getLayersByName: (name) =>
        return (l for l in @allLayers() when l.get('name') is name)

    addGeoserverLayer: (geoserverLayer, options = {}) =>
        #test GeoServer
        #url = "http://192.168.6.157:8080/geoserver/#{geoserverLayer.namespace}/wms"
        #productive geoServer

        application = if geoserverLayer.layer is 'agrosat:uav' then 'geoservern' else 'geoserver'

        url = "#{mapservers.geoserver.baseUrl}/#{application}/#{geoserverLayer.namespace}/wms"
        if geoserverLayer.parameters
            url = url + '?' + geoserverLayer.parameters
        layer = new Tile
            source: new TileWMS
                url: url
                params:
                    'LAYERS': geoserverLayer.layer
                # urls: # does server support multiple urls?
                attributions: [geoserverLayer.attribution or geoserverAttribution]

            visible: geoserverLayer.visible ? false

        if geoserverLayer.legendUrl
            layer.set 'legendUrl', geoserverLayer.legendUrl
            layer.set 'legendLayerName', geoserverLayer.legendLayerName
        layer.set 'name', geoserverLayer.layerDisplayName
        layer.set 'id', "geoserver/#{layer}"
        layer.set 'infoClick', geoserverLayer.showItemsOnFeatureClick
        @groups[options.group ? geoserverLayer.layerGroup].getLayers().push layer
        return layer

    addMapboxLayer: (id, name, options={}) =>
        layer = new Tile
            source: new XYZ
                url: "https://api.tiles.mapbox.com/v4/#{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiaXhtYXAiLCJhIjoiY2lmcGE1OHh3MDFmZ3Vja25zOGljeDl6NiJ9.EpwP84StYJW5pzQP3AuPog"
                attributions: [mapboxAttribution]
            visible: options.visible ? false
        layer.set 'name', name
        layer.set 'id', "mapbox/#{id}"
        @groups.base.getLayers().push layer
        return layer

    addBingLayer: (id, name, options={}) =>
        locale = Globalize.locale().attributes
        locale = "#{locale.language}-#{locale.region}"
        layer = new Tile
            source: new BingMaps
                key: config.bingApiKey
                imagerySet: id
                culture: locale
                attributions: [bingAttribution]
            visible: options.visible ? false
            preload: Infinity
        layer.set 'name', name
        layer.set 'id', "bing/#{id}"
        @groups.base.getLayers().push layer
        return layer

    convertVectorStyleObject = (vectorStyle) ->
        styleDefinition = {}
        for key of vectorStyle
            styleDefinition[key[0].toLowerCase() + key[1..-1]] = vectorStyle[key]
        return styleDefinition

    addMaskLayer: (imageLayer, vectorSource, layerGroup) =>
        console.warn('mask layers do not work properly yet')
        layerGroup or= @groups.data
        layers = layerGroup.getLayers()

        if layers.getLength() < 1
            throw new Error('no layer to mask')

        layer = new Image
            source: new Vector
                source: vectorSource
                style: new Style
                    fill: new Fill
                        color: 'black'
            renderMode: 'image'

        maskedLayer = layers.item(layers.getLength() - 1)
        layer.set 'name', "#{maskedLayer.get('name')}_mask"

        previousCompositeOperation = null
        layer.on 'precompose', (event) ->
            previousCompositeOperation = event.context.globalCompositeOperation
            event.context.globalCompositeOperation = 'destination-in'
        layer.on 'postcompose', (event) ->
            event.context.globalCompositeOperation = previousCompositeOperation

        layerGroup.getLayers().push layer

    createFeatures: (layerDefinition) ->
        format = new WKT

        features = []
        for f in layerDefinition.features ? []
            feature = format.readFeature f.wkt
            feature.setProperties
                name: f.label
                uid: f.uid
            feature.setId f.uid
            feature.getGeometry().transform 'EPSG:4326', 'EPSG:900913'
            features.push feature
            data = JSON.parse f.data
            for key, value of data
                feature.set key, value

            if f.style
                styleDefinition = convertVectorStyleObject f.style

                defineStyle f.style.name, styleDefinition
                feature.setStyle getStyle f.style.name

        return features

    addVectorLayer: (layerDefinition) ->
        features = @createFeatures layerDefinition

        if layerDefinition.isCluster
            layer = new VectorLayer
                source: new Cluster
                    distance: layerDefinition.clusterDistance
                    attributions: [vectorAttribution]
                    source: new VectorSource
                        features: features
                        attributions: [vectorAttribution]
                    style: getStyle 'default'

        else
            layer = new VectorLayer
                source: new VectorSource
                    attributions: [vectorAttribution]
                    features: features
                style: getStyle 'default'

        if layerDefinition.style
            styleDefinition = convertVectorStyleObject layerDefinition.style

            defineStyle layerDefinition.style.name, styleDefinition
            layer.setStyle getStyle layerDefinition.style.name

        layer.set 'name', layerDefinition.name
        layer.set 'uid', layerDefinition.uid
        layer.set 'id', "vector/#{layerDefinition.uid}"
        layer.set 'geometryType', layerDefinition.type
        layer.set 'canAdd', layerDefinition.access >= 3
        layer.set 'editable', layerDefinition.access >= 2
        layer.set 'interactive', layerDefinition.access >= 1
        layer.set 'numberOfAllowedFeatures', layerDefinition.numberOfAllowedFeatures

        group = if layerDefinition.access > 0 then 'webgis' else 'vector'

        @groups[group].getLayers().push layer

        if @mapcontrol.webgis and group is 'webgis'
            @mapcontrol.webgis.registerLayer layer

        return layer

module.exports = {
    LayerManager
}

module.exports.__esModule = true
module.exports.default = LayerManager
