# CoffeeScript

Sphere = require('ol/sphere').default
{transform} = require('ol/proj')
{getArea: sphereGetArea, getDistance: sphereGetDistance} = require('ol/sphere')

sum = (a, b) -> a + b

translationToLongLatDelta = (long, lat, distance, angle = 0) ->
    R = 6378137 # radius earth, meters
    fraction = d / R
    rad = angle * Math.PI / 180.0

    sinLat = Math.sin(lat)
    cosLat = Math.cos(lat)
    sinFrac = Math.sin(fraction)
    cosFrac = Math.cos(fraction)

    lat2 = Math.asin(sinLat * cosFrac + cosLat * sinFrac * math.cos(rad))
    long2 = long + Math.atan2(Math.sin(rad) * sinFrac * cosLat, cosFrac - sinLat * Math.sin(lat2))
    return [lat2 - lat, long2 - long]

getGeodesicDistance = getDistance = (map, c1, c2) ->
    sourceProj = map.getView().getProjection()
    c1 = transform c1, sourceProj, 'EPSG:4326'
    c2 = transform c2, sourceProj, 'EPSG:4326'
    sphereGetDistance c1, c2

getGeodesicLength = (map, line) ->
    coordinates = line.getCoordinates()

    return [0..coordinates.length - 2]
        .map (i) -> [coordinates[i], coordinates[i + 1]]
        .map (coords) -> getDistance map, coords...
        .reduce sum, 0

getGeodesicArea = (map, polygon) ->
    sourceProj = map.getView().getProjection()
    geom = polygon
        .clone()
        .transform sourceProj, 'EPSG:4326'
    return computeAreaSize geom

computeAreaSize = (geom) ->
    coordinates = geom.getLinearRing(0).getCoordinates()
    outerArea = Math.abs(sphereGetArea(coordinates))

    if geom.getLinearRingCount() > 1
        sum = (acc, curr) -> return acc + curr
        linearRingArea = [1..geom.getLinearRingCount() - 1]
        .map (x) ->
            coords = geom.getLinearRing(x).getCoordinates()
            return Math.abs(sphereGetArea(coords))
        .reduce(sum, 0)
        return outerArea - linearRingArea
    return outerArea

getCorrectionFactorForLength = (map) ->
    center = map.getView().getCenter()
    mapProjection = map.getView().getProjection()
    centerWgs84 = transform center, mapProjection, 'EPSG:4326'
    return Math.cos centerWgs84[1] * Math.PI / 180

module.exports = {
    translationToLongLatDelta,
    getGeodesicDistance,
    getGeodesicLength,
    getGeodesicArea,
    getCorrectionFactorForLength
}
