{register} = require 'src/js/infrastructure/setup-registry'

setup = ($ctx) ->
    $('#collapse').on 'click', collapseAllPannels
    $('#expand').on 'click', expandAllPannels
    $('#check').on 'click', checkAllMaps
    $('#uncheck').on 'click', uncheckAllMaps
    $('.map-utilty-check').on 'click', checkParcelMaps
    $('.map-utilty-uncheck').on 'click', uncheckParcelMaps
    $('input.mapcheckbox').on 'change', updateMapInfo
    $('input.mapcheckbox').on 'change', handleJohnDeereSendButton

handleJohnDeereSendButton = ->
    allApplicationMaps = $('input.mapcheckbox')
    selectedApplicationMapIds = []
    $johnDeerSendBtn = $('#john-deere')
    for appMap in allApplicationMaps
        $appMap = $ appMap
        if appMap.checked
            selectedApplicationMapIds.push($appMap.data 'id')

    if selectedApplicationMapIds.length is 1
        $johnDeerSendBtn.enable()
    else
        $johnDeerSendBtn.disable()

expandAllPannels = ->
    $('a.collapsed').click()

collapseAllPannels = ->
    $('a.collapse-button[aria-expanded="true"]').click()

checkAllMaps = ->
    changeCheckboxes $('input[type="checkbox"].mapcheckbox'), true

uncheckAllMaps = ->
    changeCheckboxes $('input[type="checkbox"].mapcheckbox'), false

checkParcelMaps = (event) ->
    $parcelPanel = $(event.currentTarget).closest('.parcel-panel')
    changeCheckboxes $parcelPanel.find('input[type="checkbox"].mapcheckbox'), true

uncheckParcelMaps = (event) ->
    $parcelPanel = $(event.currentTarget).closest('.parcel-panel')
    changeCheckboxes $parcelPanel.find('input[type="checkbox"].mapcheckbox'), false

changeCheckboxes = ($checkboxes, checkValue) ->
    for checkbox in $checkboxes
        checkbox.checked = checkValue
        $(checkbox).trigger('change')

updateMapInfo = (event) ->
    $checkbox = $ event.currentTarget

    $row = $checkbox.closest 'tr'
    if $checkbox[0].checked
        $row.addClass 'success'
    else
        $row.removeClass 'success'

    $infos = $checkbox.closest('.panel').find('.selectedMapOverview')
    $infoText = $checkbox.closest('.panel').find('#mapOverviewText')
    $checkboxes = $checkbox.closest('.panel-body').find('input:checked')
    categorizedMaps = []
    for info in $infos
        category = $(info).data('type')
        maps = (m for m in $checkboxes when $(m).data('type') is category)
        categoryMaps =
            maps: maps
            defaultText: $(info).data('noSelectedText')
            mapName: $(info).data('selectedText')
        categorizedMaps.push categoryMaps

    texts = []
    for cat in categorizedMaps
        texts.push getInfoTextforMaps cat.maps, cat.defaultText, cat.mapName

    $infoText.text(texts.join(', '))


getInfoTextforMaps = (applicationMaps, defaultText, mapName) ->
    switch applicationMaps.length
        when 0 then defaultText
        when 1 then $(applicationMaps[0]).data 'name'
        else "#{applicationMaps.length} #{mapName}"

register('App.DataTransfer.SelectMap.Setup', setup)
