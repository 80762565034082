{register} = require 'src/js/infrastructure/setup-registry'
{post} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

{getModal} = require 'src/coffee/controls/modals'

setup = ($ctx) ->
    $btn = $ctx.find('#john-deere')
    allApplicationMaps = $('input.mapcheckbox')

    selectedApplicationMapIds = []

    $btn.on 'click', ->
        for appMap in allApplicationMaps
            $appMap = $ appMap
            if appMap.checked
               selectedApplicationMapIds.push($appMap.data('id'))
        url = $btn.data 'url'
        data =
            applicationMapId: selectedApplicationMapIds[0]
        promise = getModal url, data
        waitscreen.waitFor promise

sendSetup = ($ctx) ->
    applicationMapId = $ctx.find('#UID').val()
    applicationMapName = $ctx.find('#Name').val()
    $sendBtn = $ctx.find('#send')
    text = $ctx.find('#question').text().replace('{map}', applicationMapName)
    $ctx.find('#question').text(text)
    url = $sendBtn.data 'url'
    $sendBtn.on 'click', ->
        post url, {applicationMapId}
        .done makeSuccessHandler $sendBtn
        .fail makeErrorHandler $sendBtn
        .always $('#send-to-john-deere').modal('hide')

register('App.DataTransfer.JohnDeere.Setup', setup)
register('App.DataTransfer.JohnDeere.SendSetup', sendSetup)