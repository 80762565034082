# CoffeeScript

Control = require('ol/control/Control').default

class Menu extends Control
    constructor: (options) ->
        @options = $.extend {}, options

        @$element = $ '<div class="ol-webgis-menu ol-unselectable ol-control">'

        Control.call this,
            element: @$element[0]
            target: @options.target

    activate: (mode) ->
        for div in @$element.find('.mode-actions')
            if div is mode.$actionContainer[0]
                $(div).collapse 'show'
            else
                $(div).collapse 'hide'

    addMode: (mode) ->
        mode.$actionContainer = $ '<div class="mode-actions collapse">'

        collapsible = @$element.children().length > 0

        mode.$actionContainer.collapse
            toggle: false

        if @$element.find('.collapse.in').length is 0
            mode.$actionContainer.addClass 'in'

        $buttonWrapper = $ '<div class="button-wrapper">'
        .append mode.$button
        .on 'click.menu', 'button', => @activate mode

        if mode.actions.length
            mode.$actionContainer.append('<div class="arrow">')

        for action in mode.actions
            mode.$actionContainer.append $('<div class="button-wrapper">').append action.$button

        @$element
        .append $buttonWrapper
        .append mode.$actionContainer

    recreate: (modes) ->
        @$element.find('button')
        .off('click.menu')
        .detach()
        @$element.empty()

        for mode, i in modes
            @addMode mode

module.exports = {
    Menu
}

module.exports.__esModule = true
module.exports.default = Menu
