{register} = require 'src/js/infrastructure/setup-registry'
{post} = require 'src/coffee/infrastructure/ajax'

{getModal} = require 'src/coffee/controls/modals'

modalSetup = ->
    $('#login').on 'click', ->
        $('#login-modal').modal('hide')
        url = $('#login-form').data 'url'
        allApplicationMaps = $('input.mapcheckbox')

        selectedApplicationMapIds = []
        for appMap in allApplicationMaps
            $appMap = $ appMap
            if appMap.checked
                selectedApplicationMapIds.push($appMap.data 'id')

        data =
            username: $('#user-name').val()
            password: $('#password').val()
            selectedApplicationMapIds: selectedApplicationMapIds

        getModal url, data, post

register('App.DataTransfer.VarioDoc.Setup', modalSetup)
