const modifierKeyState = {
  alt: false,
  ctrl: false,
  shift: false,
  meta: false
}

const setup = () => {
  $(document).on('keydown', (event) => {
    modifierKeyState.alt = event.altKey
    modifierKeyState.shift = event.shiftKey
    modifierKeyState.ctrl = event.ctrlKey
    modifierKeyState.meta = event.metaKey
    return true
  })

  $(document).on('keyup', (event) => {
    modifierKeyState.alt = event.altKey
    modifierKeyState.shift = event.shiftKey
    modifierKeyState.ctrl = event.ctrlKey
    modifierKeyState.meta = event.metaKey
    return true
  })
}

$(setup)

const getModifierKeyState = () => Object.assign({}, modifierKeyState)

export {
  getModifierKeyState
}
