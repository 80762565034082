# CoffeeScript

{Plugin} = require './base'

class EditingHelpPlugin extends Plugin
    init: (promises) ->
        promises.addedToDom.then =>
            @menu = @mapcontrol.slideoutmenu

            $button = $ '<button>'
            .addClass 'layer-switcher-button'
            .attr 'title', @mapcontrol.options.tooltips.webgis.editingHelp
            .append '<i class="fa fa-question-circle">'

            @$content = $ '<div style="white-space: initial;">'
            .append @mapcontrol.options.messages.webgis.editingHelp

            @menu.addEntry $button, @$content

module.exports = {
    EditingHelpPlugin
}

module.exports.__esModule = true
module.exports.default = EditingHelpPlugin
