{register} = require 'src/js/infrastructure/setup-registry'
{waitscreen} = require 'src/js/infrastructure'

{getModal} = require 'src/coffee/controls/modals'

setup = ($ctx) ->
    $ctx.find('#vario-doc').on 'click', loginModal
    $ctx.find('#trimble').on 'click', loginModal
    checkSendButtonsForMap()
    $('input[type="checkbox"].mapcheckbox').on 'change', ->
        checkSendButtonsForMap()

loginModal = (e) ->
    $button = $(e.currentTarget)

    promise = getModal $button.data 'init-modal-url'
    waitscreen.waitFor promise

checkSendButtonsForMap = ->
    $buttons = $('.send-to-button')
    if $('input.mapcheckbox:checked').length is 0
        for btn in $buttons
            $btn = $ btn
            $btn.disable()
    else
        for btn in $buttons
            $btn = $ btn
            $btn.enable()

register('App.DataTransfer.ApiLogin.Setup', setup)
