# CoffeeScript

Geolocation = require('ol/Geolocation').default
Feature = require('ol/Feature').default
Point = require('ol/geom/Point').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

###
enables the Geotracking Feature
@method olCustomControls.Geotracking.control

@param {ol.Map} map The Map we want the Geotracking on.
@param {string} tooltip A text Label for the Tip, of the Button which Switche the Geotracking on and off.
###
createGeotrackingControl = (map, tooltip) ->
    geolocation = new Geolocation
        projection: map.getView().getProjection()

    accuracyFeature = new Feature()
    updateAccuracyFeature = ->
        accuracyFeature.setGeometry(geolocation.getAccuracyGeometry())
    geolocation.on 'change:accuracyGeometry', updateAccuracyFeature

    transformFunction = (coordinates) ->
        if coordinates then new Point(coordinates) else null

    positionFeature = new Feature()
    updatePositionFeature = ->
        coordinates = geolocation.getPosition()
        point = new Point(coordinates) if coordinates
        positionFeature.setGeometry(point)
    geolocation.on 'change:accuracyGeometry', updatePositionFeature

    featuresOverlay = new VectorLayer
        map: map
        source: new VectorSource

    container = $(map.getTarget()).parent()
    trackButton = $('div#mapGeoTrackingButton', container)
    trackClick = (event) ->
        geolocation = event.data.geolocation
        tracking = not $.parseJSON($(this).attr('data-tracking'))
        $(this).attr('data-tracking', tracking)
        if tracking
            featuresOverlay.getSource().addFeature(accuracyFeature)
            featuresOverlay.getSource().addFeature(positionFeature)
        else
            featuresOverlay.getSource().removeFeature(accuracyFeature)
            featuresOverlay.getSource().removeFeature(positionFeature)

        geolocation.setTracking(tracking)

    trackButton.on('click', {geolocation: geolocation}, trackClick)

    return null

module.exports = {
    createGeotrackingControl
}

module.exports.__esModule = true
module.exports.default = createGeotrackingControl
