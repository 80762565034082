{waitscreen} = require 'src/js/infrastructure'

{get, post} = require 'src/coffee/infrastructure/ajax'

{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getModal} = require 'src/coffee/controls/modals'

title = document.title

# shows the options modal
showSettings = (optionsModalUrl, previewData, method = post) ->

    promise = getModal optionsModalUrl, null, method
    .done ($modal) ->
        $form = $modal.find('#print-settings-form')
        $form.on 'submit', (event) ->
            event.preventDefault()

            previewUrl = $form.prop 'action'

            settings = {}

            serialized = $form.serializeArray()

            # map the name value pairs
            for current in serialized
                settings[current.name] = current.value

            previewData.settings = settings

            showPreview previewUrl, previewData, method
            $modal.modal('hide')

# if url and data are specified, the preview will be refreshed by the server, otherwise it will only be toggled (this might allow clientside caching)
showPreview = (url, data, method = post) ->
    if (url and data)
        promise = method url, data
        .done makeReplaceHandler $('#print-preview-container')
        .then ->
            $('#close-print-preview-button').on 'click', hidePreview
        .then showPreviewInternal
        .fail hidePreview

        waitscreen.waitFor promise
    else
        showPreviewInternal()


# hides the print preview and clears it if necessary
hidePreview = (clear = false) ->
    hidePreviewInternal()

    if (clear)
        $('#print-preview-container').empty()


showPreviewInternal = ->
    $('body').addClass('print-preview-active')
    document.title = $('#print-title-container').val()


hidePreviewInternal = ->
    $('body').removeClass('print-preview-active')
    document.title = title


module.exports.__esModule = true
module.exports = {
    showSettings,
    showPreview,
    hidePreview
}