{register} = require 'src/js/infrastructure/setup-registry'
{waitscreen} = require 'src/js/infrastructure'
{postJson} = require 'src/coffee/infrastructure/ajax'

{getModal} = require 'src/coffee/controls/modals'

setupMap = ($ctx) ->
    $button = $('button#download')
    $button.on 'click', startMapDownload
    checkDownloadButtonForMap($button)
    $('input[type="checkbox"].mapcheckbox').on 'change', ->
        checkDownloadButtonForMap($button)


checkDownloadButtonForMap = ($button) ->
    if $('input.mapcheckbox:checked').length is 0
        $button.disable()
    else
        $button.enable()

startMapDownload = (e) ->
    $downloadButton = $(e.currentTarget)

    geodataSelection = {}

    for input in $('.parcel input:checked')
        $input = $(input)
        parcelId = $input.closest('.parcel').data 'parcel-id'

        unless geodataSelection[parcelId]
            geodataSelection[parcelId] = {}

        mapDetails = $input.data()
        geodataSelection[parcelId][mapDetails.id] = mapDetails.name

    url = $downloadButton.data 'modal-url'

    promise = getModal url, geodataSelection, postJson
        .then ($modal) -> $modal.data 'legacy-geodata', geodataSelection

    waitscreen.waitFor promise

setupCultivation = ($ctx) ->
    $button = $('button#submitSelectMap')
    $button.on 'click', startCutlivationDownload

startCutlivationDownload = (e) ->
    $downloadButton = $(e.currentTarget)
    parcelCombobox = $('#cultivations')[0].selectize
    cultivationIds = parcelCombobox.items

    url = $downloadButton.data 'modal-url'
    geodata = {}
    for cultivationId in cultivationIds
        parcelItem = parcelCombobox.options[cultivationId]

        geodata[cultivationId] = {}
        geodata[cultivationId][cultivationId] = "#{parcelItem.text} - #{parcelItem.label}"

    promise = getModal url, geodata, postJson
        .then ($modal) -> $modal.data 'legacy-geodata', geodata

    waitscreen.waitFor promise

setupBorder = ($ctx) ->
    $button = $('button#submitSelectMap')
    $button.on 'click', startBorderDownload

startBorderDownload = (e) ->
    $downloadButton = $(e.currentTarget)
    parcelCombobox = $('#parcels')[0].selectize
    parcelIds = parcelCombobox.items

    url = $downloadButton.data 'modal-url'
    geodata = {}
    for parcelId in parcelIds
        parcelItem = parcelCombobox.options[parcelId]

        geodata[parcelId] = {}
        geodata[parcelId][parcelId] = "#{parcelItem.text} - #{parcelItem.label}"

    promise = getModal url, geodata, postJson
        .then ($modal) -> $modal.data 'legacy-geodata', geodata

    waitscreen.waitFor promise

register('App.DataTransfer.Download.SetupMap', setupMap)
register('App.DataTransfer.Download.SetupBorder', setupBorder)
register('App.DataTransfer.Download.SetupCultivation', setupCultivation)
