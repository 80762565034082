{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

{waitscreen} = require 'src/js/infrastructure'

setup = ($ctx) ->
    $sendButton = $ctx.find('#send-to')

    $sendButton.on 'click', ->

        appMaps = []
        $products = $('select.products')

        for prod in $products
            $prod = $ prod
            mapInfo =
                ApplicationMapId: $prod.data 'map-id'
                ApplicationMapName: $prod.data 'map-name'
                SelectedProduct: $prod.val()
            appMaps.push(mapInfo)

        data =
            MachineIds: $('#machine-ids').val()
            ApplicationMaps: appMaps
            CulturalPracticeDesignator: $('#designator').val()
            Username: $('#Username').val()
            Password: $('#Password').val()
        url = $('#data-transfer-parameter').data 'url'

        promise = antiForgeryPost url, data
            .done makeSuccessHandler $sendButton
            .fail makeErrorHandler $sendButton
        waitscreen.waitFor promise

register('App.DataTransfer.SendToTerminal.Setup', setup)
